import logo from './Baaz.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Ero! Tulia</h1>
        <p>
          Iko jikoni
        </p>
        
      </header>
    </div>
  );
}

export default App;
